.gallery {
  .gallery-image {
    box-sizing: border-box;
    padding: 16px 0;
    @include breakpoint-md {
      padding: 24px 0;
    }

    img {
      all: revert;
      display: block;
      width: 100%;
    }
  }

  .gallery-image-0 {
    @include breakpoint-md{
      img, .zentis-video-wrap {
        width: calc(50vw - 80px);
        max-width: calc(50vw - 80px);
        position: relative;
        left: 100%;
        transform: translateX(-100%);
      }
    }
    @media(min-width: 1780px){
      img, .zentis-video-wrap {
        width: 180%;
        max-width: 180%;
      }
    }
  }

  .gallery-image-video {
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &[data-aspect-ratio="Desktop_1_1"] {
      .zentis-video-wrap{
        padding-bottom: 100%;
      }
    }
    &[data-aspect-ratio="Desktop_16_9"] {
      .zentis-video-wrap{
        padding-bottom: calc(100%  * 0.5625);
      }
    }
    &[data-aspect-ratio="Desktop_9_16"] {
      .zentis-video-wrap{
        padding-bottom: calc(100%  * 1.77);
      }
    }
    &[data-aspect-ratio="Desktop_4_3"] {
      .zentis-video-wrap{
        padding-bottom: calc(100%  * 0.75);
      }
    }
  }

  .gallery-image-0 {
    &.gallery-image-video{
      .zentis-video-wrap {
        padding-bottom: calc(180% * 0.5625);
      }
    }
  }
}



