.pimcore_area_ZentisText {
  ul {
    list-style: none;
    margin-top: 24px;

    li {
      padding-bottom: 1px;
    }
    li::before{
      position: absolute;
      content: "\2022";
      color: #000;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1.1em;
      margin-top: -2px;
    }

  }

  @each $theme, $themeMap in $themes {
    ul.bullet-color-#{$theme} li::before {
      color: map-get($themeMap, "textColor");
    }
  }
}
