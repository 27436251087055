$transistionSpeed: 600ms;

html.no-scroll {
  height: 100vh;
  overflow: hidden;


  &.is-ios {
    body {
      height: 100vh;
      overflow: hidden;
    }
  }

  @include breakpoint-desktop-navi() {
    height: auto;
    overflow: auto;
  }
}

nav.main-nav {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  height: 64px;
  width: 100vw;
  color: $zentis-gray-900;

  .nav-trigger {
    position: absolute;
    right: 16px;
    top: 8px;
    background: transparent;
    border: 0;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    padding: 0;

    &:focus, &:active {
      outline: none;
    }

    .icon {
      width: 16px;
    }

    .icon-close {
      display: none;
    }
  }

  .nav-language-switcher.nav-language-switcher-header {
    position: absolute;
    right: 80px;
    top: 20px;
  }

  a {
    color: currentColor;
    text-decoration: none;

    &:active, &:visited {
      color: currentColor;
    }
  }

  &.open-highlight {
    width: 100px;
  }

  .nav-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 8px;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 16%);
    @include frostet-glass-bg;

    /**
      this is a hack, that prevents chorme flickering on animations/hover.
      the flickering occurs only because of the backdrop-filter css
     **/
    &:before {
      content: "";
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      @include breakpoint-desktop-navi() {
        z-index: -1;
        width: 0;
        height: 0;
        background: none;
      }
    }
  }

  .nav-menu-scroll-container {
    box-sizing: border-box;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -1;
    padding: 64px 24px 24px;
    transform: translateY(-100%);
    transition: transform ease $transistionSpeed;
    overflow-x: hidden;

    @include frostet-glass-bg;
    @include scrollbar();
  }

  .nav-menu-list {
    padding-top: 16px;
    margin-bottom: 24px;

    .nav-item {
      margin: 24px 0;
      display: flex;
      align-items: center;
      height: 32px;
      position: relative;
      padding-left: 48px;
    }

    .nav-item-secondary {
      padding-left: 48px;
      display: none;

      .nav-title {
        font-weight: 300;
      }
    }

    .icon {
      width: 32px;
      margin-right: 16px;
      transition: width linear 200ms;
      flex: 0 0 auto;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .nav-title {
      font-weight: 600;
      white-space: nowrap;
      opacity: 0;
      transition: opacity ease $transistionSpeed;
      text-decoration: none;
      color: currentColor;
    }
  }

  .nav-social-list {
    padding-left: 48px;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity ease $transistionSpeed;

    .nav-social-list-wrapper {
      margin: 0 -8px;
      display: flex;
    }

    .social-item {
      margin: 0 8px;
    }
  }

  $logoTransitionSpeed: 250ms;

  .nav-logo {
    flex: 0 0 auto;
    width: 48px;
    height: 16px;
    position: absolute;
    left: 24px;
    transform: translateY(-50%);
    top: 50%;
    transition: left ease $logoTransitionSpeed, width ease $logoTransitionSpeed, height ease $logoTransitionSpeed;

    .icon-logo {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      background: $zentis-red;
      width: 100%;
      height: 100%;
    }

    .icon-logo-crown {
      position: absolute;
      top: 0;
      left: 0;
      background: $zentis-red;
      mask-position: -46px -12px;
      mask-size: 138px;
      width: 100%;
      height: 100%;
      transition: mask-position ease $logoTransitionSpeed, mask-size ease $logoTransitionSpeed;
    }
  }

  //opened state
  &.opened {
    overflow: visible;

    .nav-wrapper {
      backdrop-filter: none;
      @media (max-width: #{$desktop-navi-min}) {
        background: rgba(255,255,255,0.95);
      }
    }

    .nav-wrapper::before {
      background: rgba(255, 255, 255, 0.85);
      @include breakpoint-desktop-navi() {
        background: none;
      }
    }

    .nav-trigger {
      .icon-close {
        display: inline-block;
      }

      .icon-burger {
        display: none;
      }
    }

    .nav-menu-scroll-container {
      transform: translateY(0);
    }

    .nav-menu-list {
      .icon {
        //transform: scale(0.625);
        width: 20px;
      }

      .nav-item-secondary {
        display: block;
      }

      .nav-title {
        display: inline;
        opacity: 1;
      }
    }

    .nav-social-list {
      height: auto;
      visibility: visible;
      opacity: 1;
    }
  }
}


//second layers
nav.main-nav {
  .nav-second-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    transform: translateY(-100%);
    min-width: 500px;
    height: 100%;
    overflow: hidden;
    transition: transform $transistionSpeed ease;

    @include frostet-glass-bg;

    /**
      this is a hack, that prevents chorme flickering on animations/hover.
      the flickering occurs only because of the backdrop-filter css
     **/
    &:before {
      content: "";
      z-index: -1;
      position: absolute;
    }

    &.active {
      transform: translateY(0);
    }
  }

  .nav-second-wrapper-inner {
    box-sizing: border-box;
    width: 100vw;
    padding-left: 44px;
  }

  .nav-second-container {
    padding: 32px 32px 32px 30px;
    display: none;
    box-sizing: border-box;
    max-height: 100vh;
    margin-top: 64px;
    @include scrollbar();


    &.active {
      display: block;
    }

    a {
      font-weight: 300;
    }

    .nav-parent-link {
      margin-bottom: 32px;


      a {
        font-weight: 600;
      }
    }

    &.nav-second-container-search {
      overflow: visible;

      h4 {
        margin-top: 0;
        margin-bottom: 16px;
      }

      .nav-search-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
        @media screen and (min-width: 468px) {
          flex-wrap: nowrap;
        }

        .form-group {
          width: 100%;

          .form-control {
            background: transparent;
            @include zentis-theme() {
              &::placeholder {
                color: theme-value('buttonColor');
              }
            }
          }
        }

        .nav-search-submit {
          margin: 8px;
          padding: 16px;
          box-sizing: border-box;
        }

      }
    }

    &.nav-second-container-imagelist {
      /* max-width: 900px;*/
      width: 100%;

      .nav-imagelist-wrapper {
        margin-bottom: 36px;
      }

      .nav-imagelist-headline {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 4px;
      }

      .nav-imagelist {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;

        li {
          margin: 4px;
          max-width: 144px;
        }

        .nav-imagelist-link {
          display: block;
          margin-bottom: 10px;

          img {
            display: block;
            width: 144px;
            height: 135px;
            margin-bottom: 0px;
          }

          span {
            display: block;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
    }

    &.nav-second-container-linklist {
      .nav-linklist {
        margin: -16px 0 0 0;
        padding: 0;
        list-style: none;

        li {
          padding: 8px 0;
          margin: 8px 0;
        }
      }
    }

  }

  .nav-back {
    margin-left: -34px;
    margin-bottom: 24px;

    .icon {
      width: 16px;
      margin-right: 18px;
    }

    a {
      display: flex;
      align-items: center;
      font-weight: 600;
    }
  }
}


// Desktop Navigation
@include breakpoint-desktop-navi() {

  .main-nav-proximity-helper {
    position: fixed;
    top: 0;
    left: 80px;
    width: 60px;
    height: 100%;
    background: transparent;
  }

  nav.main-nav {
    height: auto;
    width: 80px;
    bottom: 0;
    transition: width ease $transistionSpeed;

    .nav-trigger {
      display: none;
    }

    .nav-language-switcher.nav-language-switcher-header {
      display: none;
    }

    .nav-wrapper {
      padding: 224px 24px 24px;
      box-shadow: 4px 0 16px 0 rgb(0 0 0 / 16%);
    }

    .nav-menu-scroll-container {
      margin: 0 -24px;
      padding: 24px 24px 0;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      width: auto;
      background: none;
      z-index: 0;
      backdrop-filter: none;
      transform: translateY(0);
    }

    .nav-menu-list {
      padding-top: 0;

      .nav-item {
        /* &:after {
           content: "";
           width: 32px;
           height: 32px;
           background: red;
           display: block;
           position: absolute;
           top: 50%;
           right: 0;
           transform: translate(-50%, 100%);
         }*/
      }
    }

    .nav-logo {
      left: 50%;
      transform: translateX(-50%);
      top: 48px;
    }

    .nav-back {
      display: none;
    }
  }

  //opened state
  nav.main-nav {
    &.opened {
      width: 260px;

      .nav-wrapper {
        @include frostet-glass-bg;
      }

      .nav-logo {
        width: 98px;
        height: 56px;

        .icon-logo {
          opacity: 1;
          transition: opacity ease 0.6s 0.25s;
        }

        .icon-logo-crown {
          mask-size: 100%;
          mask-position: 0 0;
        }
      }
    }
  }

  //second layers
  nav.main-nav {
    .nav-second-wrapper {
      width: auto;
      transform: translateX(-100%);

      &.active {
        transform: translateY(0);
      }
    }

    .nav-second-wrapper-inner {
      width: auto;
      padding-left: 260px;
    }

    .nav-second-container {
      padding: 32px;

      &.nav-second-container-search {
        margin-top: 160px;
        width: 963px;
        max-width: calc(100vw - 260px);
        padding-left: 160px;
        padding-right: 160px;
      }

      &.nav-second-container-imagelist {
        padding: 0px 64px;

        .nav-imagelist {
          li {
            margin: 0px 20px;
            max-width: 165px;
          }
        }
      }

      &.nav-second-container-linklist {
        margin-top: 242px;
      }

      .nav-parent-link {
        display: none;
      }
    }
  }
}

