.header-stage {
  position: relative;
  min-height: 130px;
  margin-top: 25px;

  &.header-stage-editmode {
    .stage-image {
      margin-left: 0 !important;
    }
  }

  @media screen and (min-width: $screen-xs-min) {
    margin-top: 64px;
  }
  @media screen and (min-width: $screen-md-min) {
    min-height: 125px;
    margin-top: 0;
  }

  .stage-image {
    display: block;
    width: 100%;
    @media screen and (min-width: $desktop-navi-min) {
      width: 100vw;
      margin-left: -80px;
    }
  }

  .stage-badge {
    height: 0;
    width: auto;
    position: relative;
    margin-top: -64px;
    @media screen and (min-width: $screen-xs-min) {
      height: 25%;
      width: auto;
      position: relative;
      margin: -64px auto 30px auto;
    }

    @media screen and (min-width: $screen-md-min) {
      height: 15%;
      min-height: 100px;
      margin: -64px auto 56px auto;
    }

    @media screen and (min-width: $screen-xl-min) {
      height: 20%;
    }
  }

  video {
    position: relative;
    display: block;
    top: auto;
    left: auto;
    height: auto;
    width: auto;
    max-width: 100%;
  }

  @media screen and (min-width: $desktop-navi-min) {
    video {
      margin-left: -80px;
      width: calc(100% + 80px);
      max-width: none;
    }
  }

  .stage-content {
    position: absolute;
    bottom: 13%;
    left: 0;
    right: 0;
    padding-left: 24px;
    padding-right: 24px;
    top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include fluid-font-size(0px, $screen-lg-min, 16px, 48px);

    @media screen and (min-width: $desktop-navi-min + 1) {
      padding-top: 0;
    }

    &.stage-content-relative {
      position: relative;
      padding-top: 64px;
      top: 0;
    }

    &.stage-content-top {
      justify-content: flex-start;
    }

    &.stage-content-bottom {
      justify-content: flex-end;
    }

    &.stage-content-center {
      justify-content: center;
    }

    .icon-logo {
      display: none;
      margin-top: 5px;
      margin-bottom: 28px;

      @media screen and (min-width: $screen-xs-min) {
        display: block;
      }

      @media screen and (min-width: $screen-xl-min) {
        margin-bottom: 56px;
      }

    }

    h1 {
      font-weight: 400;
      font-size: 1.5em;
      line-height: 1.1;
      margin: 0;
    }

    .stage-subline {
      text-align: center;
      max-width: 776px;
      font-family: $font-calluna;
      font-size: 1em;
      font-weight: 400;
      line-height: 1.125;
      color: $zentis-gray-900;
      @include zentis-theme(true) {
        color: theme-value('headlineColor');
      }
      @media screen and (min-width: $screen-xs-min) {
        line-height: 1.4;
      }
    }

    .content-image {
      width: auto;
      max-width: 100%;
    }
  }

}

.stage-image-wrapper {
  img {
    @media screen and (min-width: $desktop-navi-min) {
      width: 100vw;
      margin-left: -80px;
    }
  }
}