.form-choice-wrapper {
    display: flex;
    margin: 23px 0;

    .choice-options-wrapper {
        display: flex;
        flex-flow: column;
        margin-top: 10px;
    }

    .choice {
        display: flex;
        flex-flow: row;
        margin-bottom: 10px;

        label {
            margin-left: 10px;
        }
  
        /* When the checkbox is checked, add a blue background */
        input  {
            accent-color: black;
            // background-color: #000000;
        }
    }
}

#ingredient-question-form {
    display: none;
}

#product-question-form {
    display: none;
}

#general-question-form {
    display: none;
}

.copy {
    margin: 20px 0;
}

.progress-bar-wrapper {
    display: flex;
    width: 100%;
    height: 112px;
    background-color: #F5F5F5;
    margin-bottom: 20px;
    align-items: center;
    padding: 0 20px 0 20px;

    .active > .progress-number {
        background-color: #84B65B;
        color: white;
    }

    .active > .progress-label {
        font-weight: 500;
    }

    .progress-step {
        display: flex;
        flex-flow: row;
        align-items: center;
    }

    .progress-number {
        width: 47px;
        height: 47px;
        border: 1px solid #84B65B;
        color: #84B65B;
        font-weight: 500;
        text-align: center;
        border-radius: 47px;
        line-height: 45px;
        background-color: white;
    }

    .progress-label {
        display: none;

        margin-left: 10px;
        align-self: center;
        color: #84B65B;
        max-width: 200px;
        font-size: 12px;
        word-break: break-word;
        font-weight: 200;

        @include breakpoint-md {
            display: block;
        }

        @include breakpoint-lg {
            display: block;
            font-size: 16px;
        }
    }

    .progress-divider {
        flex-grow: 1;
        height: 1px;
        background-color: #84B65B;
        margin: 0 10px;
    }
}

.success-copy {
    margin-top: 20px;
}

.read-more {
    display: inline-flex;
    align-items: center;
    padding: 16px;
    margin: 32px 0px 0px 0px;
    border-radius: 3px;
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    line-height: 1;
    font-family: Fakt,sans-serif;
    border: 1px solid #84B65B;
    background: #84B65B;
    cursor: pointer;
}

.read-more:hover {
    box-shadow: 0 0 6px 1px rgba(0,0,0,.4);
    color: #ffffff;
}

.complaint-form {

    #complaintform {
        display: none;
    }

    .form-step {
        display: none;
        opacity: 0;
        transition: opacity 1s ease-out;
        opacity: 0;

        &.active {
            display: block;
            opacity: 1;
        }
    }
    
    &_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
    }
    &_column--half {
        grid-column: span 2;
        @include breakpoint-md {
            grid-column: span 1;
        }
    }
    &_column--full {
        grid-column: span 2;
    }

    .no-date-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;

        label {
            margin-left: 10px;
        }
    }

    .no-time-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;

        label {
            margin-left: 10px;
        }
    }

    .no-lot-number-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;

        label {
            margin-left: 10px;
        }
    }

    .form- h2 {
        margin-top: 24px;
    }

    .mimi-header {
        padding-top: 8px;
        font-size: 12px;
        line-height: 12px;
        font-weight: 300;
    }
    #complaintform_product,
    #complaintform_productType {
        option {
            &:first-child {
                color: lighten(#000, 50%);
            }
        }
    }

    .terms-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
    }

    input:invalid {
        border: 2px dashed red;
    }

    #complaintform_bestBeforeDate {
        .form-inline {
            flex-wrap: wrap;
            .form-group {
                flex-grow: 1;
                flex-basis: 100%;
            }
            @include breakpoint-md {
                flex-wrap: nowrap;
                .form-group {
                    flex-grow: 1;
                }
            }
        }
        select {
            min-width: 0 !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            margin-right: 8px;
            &:last-child {
                margin-right: inherit;
            }
        }
    }
    #complaintform_image {
        margin-right: 8px;
    }

    #complaintform_submit:disabled {
        background-color: lighten(#000, 50%);
        cursor: not-allowed;
    }

    .error-submit-button {
        display: none;
        color: red;
    }
}
