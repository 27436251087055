.breadcrumbs {
  margin: 32px 0 48px 0;

  .breadcrumb-header {
    font-weight: 300;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      display: flex;
      align-items: center;
      padding: 12px 0;
    }

    a {
      color: $zentis-gray-900;
      text-decoration: none;
    }

    .icon-arrow-thin-right {
      width: 16px;
      display: inline-block;
      margin: 0 8px;
      background-color: $zentis-gray-900;
    }
  }
}