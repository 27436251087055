form {
    padding: 20px 20px 20px 0px; 

    input[type="text"], input[type="email"], select {
        border-radius: 4px !important;
        padding: 16px !important;
        font-size: 16px !important;
        min-width: 230px !important;
        width: 100% !important;
        box-sizing: border-box !important;
    }

    span {
        label {
            margin-left: 10px;
        }
    }

    button {
        align-items: center;
        margin: 16px 8px 8px 0px !important;
        border-radius: 3px !important;
        text-decoration: none !important;
        color: #fff !important;
        font-size: 16px !important;
        padding: 16px !important;
        line-height: 1 !important;
        font-family: Fakt, sans-serif !important;
        border: 1px solid #74b94d !important;
        background-color: #74b94d !important;
        cursor: pointer;
    }
}