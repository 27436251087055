/**
Margin sizes can be used as css classes as following examples:

m-[size|direction]
m-[size]-[direction]

"m-l": margin: 32px;
"m-t": margin-top: 24px;
"m-xl-b": margin-bottom: 40px;
 */

$margin-spacing-sizes:  ('xxs': $margin-xxs, 'xs': $margin-xs, 's': $margin-s, 'm': $margin-m, 'l': $margin-l, 'xl': $margin-xl, 'xxl': $margin-xxl, 'xxxl': $margin-xxxl);
$directions: ('t': 'top', 'r': 'right', 'b': 'bottom', 'l': 'left');

//Default size for margins is $margin-m
@each $d, $direction in $directions{
  .m-#{$d} {
    margin-#{$direction}: $margin-m;
  }
}

//margin sizes for each defined size and direction
@each $size, $sizeValue in $margin-spacing-sizes {
  .m-#{$size} {
    margin: $sizeValue;
  }

  @each $d, $direction in $directions{
    .m-#{$size}-#{$d} {
      margin-#{$direction}: $sizeValue;
    }
  }
}

/**
Padding sizes can be used as css classes as following examples:

p-[size|direction]
p-[size]-[direction]

"p-l": padding: 32px;
"p-t": padding-top: 24px;
"p-xl-b": padding-bottom: 40px;
 */

$padding-spacing-sizes:  ('xxs': $padding-xxs, 'xs': $padding-xs, 's': $padding-s, 'm': $padding-m, 'l': $padding-l, 'xl': $padding-xl, 'xxl': $padding-xxl, 'xxxl': $padding-xxxl);

//Default size for paddings is $padding-m
@each $d, $direction in $directions{
  .p-#{$d} {
    padding-#{$direction}: $padding-m;
  }
}

//padding sizes for each defined size and direction
@each $size, $sizeValue in $padding-spacing-sizes {
  .p-#{$size} {
    padding: $sizeValue;
  }

  @each $d, $direction in $directions{
    .p-#{$size}-#{$d} {
      padding-#{$direction}: $sizeValue;
    }
  }
}