@import 'header';
@import 'navigation';
@import 'footer';
@import 'scroll_top';



html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Fakt', sans-serif;
}

body {
  font-size: 16px;
  line-height: 1.6;
  overflow-x: hidden;
}

.body-wrapper {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  @include breakpoint-desktop-navi() {
    padding-left: 80px;
  }
}

#main-content {
  @media screen and (min-width: 769px) {
    margin-top: 0;
  }
}

.header-stage ~ #main-content{
  margin-top: 0;
}


img {
  width: 100%;
  height: auto;
}

.themed-color {
  @include zentis-theme() {
    color: theme-value('textColor');
  }
}

.show-more-link {
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  .icon {
    margin-right: 0;
    margin-left: 8px;
    width: 16px;
    background-color: currentColor;
  }
}

.pimcore_area_ZentisText.pimcore_area_content,
.pimcore_area_ZentisProductNutritions.pimcore_area_content{
  p,th,td{
    font-size: 20px;
    line-height: 1.5em;
    @media screen and (max-width: $screen-md-min){
      font-size: 18px;
      line-height: 1.5em;
    }
  }
}

.pimcore_area_ZentisProductTeaser{
  .row{
    justify-content: space-evenly;
  }
}
