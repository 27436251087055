table.nutritions {
  width: 100%;
  border-collapse: collapse;

  tr {
    border-top: 1px solid #e4e4e4;

    &.indented {
      border-top: none;

      th {
        padding-left: 1rem;
        padding-top: 0;
      }

      td {
        padding-top: 0;
      }
    }
  }

  th {
    padding: .25rem 0;
    text-align: left;
    font-weight: 300;
  }

  td {
    padding: .25rem 0;
    text-align: right;
  }
}