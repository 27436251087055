@import "../variables/breakpoints";

// Small devices
@mixin breakpoint-sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin breakpoint-md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin breakpoint-lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin breakpoint-xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

// desktop Navi
@mixin breakpoint-desktop-navi {
    @media (min-width: #{$desktop-navi-min}) {
        @content;
    }
}