.product-nutritions {
  .pn-detail {
    display: none;

    .pn-header {
      margin: 0 auto;

      h2 {
        margin-top: 8px;
      }
    }
  }

  .pn-els {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0;
    justify-content: center;
  }

  .pn-el {
    text-align: center;
    text-decoration: none;
    flex-basis: 50%;
    margin: 8px 0;
    @include zentis-theme() {
      color: theme-value('textColor');
    }


    svg {
      fill: currentColor;
      width: 48px;
      height: 48px;
      display: block;
      margin: 0 auto;
    }

    .svg-nusspli-bar,
    .svg-belmanda-bar-nougat,
    .svg-belmanda-bar-cocos,
    .svg-belmanda-bar-classic,
    .svg-nr-stueckig-wildberry{
      stroke: currentColor;
    }

    p {
      width: 100px;
      margin: 8px auto 12px;
      font-size: 0.875rem;
      font-weight: 300;
    }

    &:hover {
      @include zentis-theme() {
        color: $zentis-gray-900;
      }
    }

  }

  .pn-desc-header {
    margin-bottom: 24px;
  }
}

@media (min-width: $screen-md-min) {
  .product-nutritions {
    .pn-detail:first-child {
      display: block;
    }

    .pn-content {
      display: flex;
      margin: 0 -16px;

      > div {
        flex-basis: 50%;
        padding: 0 16px;
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .product-nutritions {
    .pn-el {
      flex-basis: inherit;
      margin: 0 12px;
    }
  }
}
