/* roboto-regular - latin */
@font-face {
  font-family: 'Fakt';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../fonts/Fakt-Light.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/Fakt-Light.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

@font-face {
  font-family: 'Fakt';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/Fakt-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/Fakt-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

@font-face {
  font-family: 'Fakt';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../fonts/Fakt-SemiBold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/Fakt-SemiBold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}


@font-face {
  font-family: 'Northwell';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/Northwell.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/Northwell.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

@font-face {
  font-family: 'Calluna';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/Calluna-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../fonts/Calluna-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}


@font-face {
  font-family: 'BebasNeue';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/BebasNeueRegular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'ColbyCpReg';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/ColbyCpReg.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'ColbyCpBld';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../fonts/ColbyCpBld.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'DirtyBag';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/DirtyBag.woff2') format('woff2');
  font-display: swap;
}


@font-face {
  font-family: 'GothamSSm-Bold';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/GothamSSm-Bold_Web.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GothamSSm-Bold';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('../fonts/GothamSSm-BoldItalic_Web.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GothamSSm-Book';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/GothamSSm-Book_Web.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GothamSSm-Book';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('../fonts/GothamSSm-BookItalic_Web.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Gratinoli';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/Gratinoli.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/MyriadProRegular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'LunchBox';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/LunchBoxBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTProLt';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../fonts/HelveticaNeueLTProLt.woff2') format('woff2');
  font-display: swap;
}


$font-fakt: 'Fakt', sans-serif;
$font-calluna: 'Calluna', sans-serif;
$font-northwell: 'Northwell', sans-serif;

$font-bebas-neue: 'BebasNeue', sans-serif;
$font-colby-cp-bold: 'ColbyCpBld', sans-serif;
$font-colby-cp-reg: 'ColbyCpReg', sans-serif;
$font-dirty-bag: 'DirtyBag', sans-serif;
$font-gotham-book: 'GothamSSm-Book', sans-serif;
$font-gotham-bold: 'GothamSSm-Bold', sans-serif;
$font-gratinoli: 'Gratinoli', sans-serif;
$font-myriad-pro: 'MyriadPro', sans-serif;
$font-lunch-box: 'LunchBox', sans-serif;
$font-helvetica-neue-light: 'HelveticaNeueLTProLt', sans-serif;