.navbar-nav {
  list-style-type: none;

  li {
    position: relative;
    display: inline-block;
    margin: 0 20px;
    a {
      color:white;
      text-decoration: none;
      font-family: $font-fakt;
      font-size:20px;
    }
    ul {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      margin: 0;
      padding: 0;
    }

    &:hover > ul {
      display: block;
    }
  }
}

.navbar-meta {
  .navbar-nav {
    li {
      a {
        font-size:14px;
      }
    }
  }
}