.icon {
  width: 24px;
  display: inline-block;
  mask-size: cover;
  background: $zentis-gray-900;
  mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAEUlEQVR42mNkIAAYRxWMJAUAE5gAEdz4t9QAAAAASUVORK5CYII=); //16x16 transparent png

  &:before {
    content: "";
    display: block;
    height: 0;
    padding-bottom: 100%;
  }
}

.icon-logo, .icon-logo-crown {
  width: 136px;
  mask-image: url("../images/icons/logo-transparent.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: 50%;
  &:before{
    padding-bottom: 57.5%;
  }

  &.icon-logo-red {
    background: $zentis-red;
  }

  &.icon-logo-white {
    background: #FFFFFF;
  }

  &.icon-logo-black {
    background: #000000;
  }
}

.icon-logo-crown {
  mask-image: url("../images/icons/logo-crown-transparent.svg");
  mask-size: 301%;
  mask-position: 51% 13%;
}

.icon-home {
  mask-image: url("../images/icons/home.svg");
}

.icon-search {
  mask-image: url("../images/icons/search.svg");
}

.icon-products {
  mask-image: url("../images/icons/products.svg");
}

.icon-spot {
  mask-image: url("../images/icons/spot.svg");
}

.icon-products-nr {
  mask-image: url("../images/icons/products-nr.svg");
}

.icon-products-nr2 {
  mask-image: url("../images/icons/products-nr2.svg");
}

.icon-principles {
  mask-image: url("../images/icons/principles.svg");
}

.icon-sustainability {
  mask-image: url("../images/icons/sustainability.svg");
}

.icon-campaign {
  mask-image: url("../images/icons/campaign.svg");
}

.icon-leaf {
  mask-image: url("../images/icons/leaf.svg");
}

.icon-industrial {
  mask-image: url("../images/icons/industrial.svg");
}

.icon-innovation {
  mask-image: url("../images/icons/innovation.svg");
}

.icon-blog {
  mask-image: url("../images/icons/blog.svg");
}

.icon-info {
  mask-image: url("../images/icons/info.svg");
}

.icon-facebook {
  mask-image: url("../images/icons/facebook.svg");
}

.icon-instagram {
  mask-image: url("../images/icons/instagram.svg");
}

.icon-youtube {
  mask-image: url("../images/icons/youtube.svg");
}

.icon-burger {
  mask-image: url("../images/icons/burger.svg");
}

.icon-close {
  mask-image: url("../images/icons/close.svg");
}

.icon-download-nr2 {
  mask-image: url("../images/icons/download-nr2.svg");
}

.icon-mail {
  mask-image: url("../images/icons/mail.svg");
}

.icon-print {
  mask-image: url("../images/icons/print.svg");
}

.icon-arrow-left {
  mask-image: url("../images/icons/arrow.svg");
}

.icon-arrow-right {
  mask-image: url("../images/icons/arrow.svg");
  transform: rotate(180deg);
}

.icon-arrow-down {
  mask-image: url("../images/icons/arrow.svg");
  transform: rotate(-90deg);
}

.icon-arrow-up {
  mask-image: url("../images/icons/arrow.svg");
  transform: rotate(90deg);
}

.icon-arrow-thin-left {
  mask-image: url("../images/icons/arrow-thin.svg");
}

.icon-arrow-double-thin-left {
  mask-image: url("../images/icons/arrow-double-thin.svg");
}

.icon-arrow-thin-right {
  mask-image: url("../images/icons/arrow-thin.svg");
  transform: rotate(180deg);
}

.icon-arrow-double-thin-right {
  mask-image: url("../images/icons/arrow-double-thin.svg");
  transform: rotate(180deg);
}

.icon-arrow-thin-down {
  mask-image: url("../images/icons/arrow-thin.svg");
  transform: rotate(-90deg);
}

.icon-arrow-thin-up {
  mask-image: url("../images/icons/arrow-thin.svg");
  transform: rotate(90deg);
}

.icon-natur-liebe-challenge {
  mask-image: url("../images/icons/natur-liebe-challenge.svg");
  transform: rotate(90deg);
}

.icon-b2b-about-us{
  mask-image: url("../images/icons/B2B/b2b_about_us.svg");
}

.icon-b2b-contact{
  mask-image: url("../images/icons/B2B/b2b_contact.svg");
}

.icon-b2b-innovations{
  mask-image: url("../images/icons/B2B/b2b_innovations.svg");
}

.icon-b2b-location{
  mask-image: url("../images/icons/B2B/b2b_location.svg");
}

.icon-b2b-logistics{
  mask-image: url("../images/icons/B2B/b2b_logistics.svg");
}

.icon-b2c-bauerninitiative{
  mask-image: url("../images/icons/B2C/b2c_bauerninitiative.svg");
}

.icon-b2b-portfolio{
  mask-image: url("../images/icons/B2B/b2b_portfolio.svg");
}

.icon-b2c-about-us{
  mask-image: url("../images/icons/B2C/b2c_about_us.svg");
}

.icon-b2c-career{
  mask-image: url("../images/icons/B2C/b2c_career.svg");
}

.icon-b2c-contact{
  mask-image: url("../images/icons/B2C/b2c_contact.svg");
}

.icon-b2c-industry{
  mask-image: url("../images/icons/B2C/b2c_industry.svg");
}

.icon-b2c-markenwelt{
  mask-image: url("../images/icons/B2C/b2c_Markenwelt.svg");
}

.icon-b2c-news{
  mask-image: url("../images/icons/B2C/b2c_news.svg");
}

.icon-b2c-recipes{
  mask-image: url("../images/icons/B2C/b2c_recipes.svg");
}

.icon-general-download{
  mask-image: url("../images/icons/general/general_download.svg");
}

.icon-general-information{
  mask-image: url("../images/icons/general/general_information.svg");
}

.icon-general-mail{
  mask-image: url("../images/icons/general/general_mail.svg");
}

.icon-general-print{
  mask-image: url("../images/icons/general/general_print.svg");
}

.icon-general-save{
  mask-image: url("../images/icons/general/general_save.svg");
}

.icon-general-search{
  mask-image: url("../images/icons/general/general_search.svg");
}

.icon-general-time{
  mask-image: url("../images/icons/general/general_time.svg");
}

.icon-general-web{
  mask-image: url("../images/icons/general/general_web.svg");
}

.icon-general-zentis-logo{
  mask-image: url("../images/icons/general/general_zentis-logo.svg");
}

.icon-general-zentis-crown{
  mask-image: url("../images/icons/general/general_zentis_crown.svg");
}

.icon-sm-facebook{
  mask-image: url("../images/icons/socialmedia/sm_facebook.svg");
}

.icon-sm-instagram{
  mask-image: url("../images/icons/socialmedia/sm_instagram.svg");
}

.icon-sm-linkedin{
  mask-image: url("../images/icons/socialmedia/sm_linkedin.svg");
}

.icon-sm-pinterest{
  mask-image: url("../images/icons/socialmedia/sm_pinterest.svg");
}

.icon-sm-youtube{
  mask-image: url("../images/icons/socialmedia/sm_youtube.svg");
}

.icon-studioz{
  mask-image: url("../images/icons/studio_z.svg");
}

.icon-ventures{
  mask-image: url("../images/icons/zentis_ventures.svg");
}

.icon-fruit-solutions{
  mask-image: url("../images/icons/zentis_fruit_solutions_small.svg");
}

.icon-b2c-nature {
  mask-image: url("../images/icons/b2c_nature.svg");
}