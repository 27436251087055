.social-networks{
    a{
        margin: 10px 5px;
        &:first-child{
            margin-left: 0;
        }

        @media screen and (min-width: $screen-md-min ) and (max-width: $screen-lg-min) {
          margin: 10px 3px;
        }
    }


    .social-network-icon {
        position: relative;
        display: inline-block;
        height: 50px;
        width: 50px;
        mask-repeat: no-repeat;
        mask-size: contain;

        @media screen and (min-width: $screen-md-min) {
          height: 30px;
          width: 30px;
        }

        @media screen and (min-width: $screen-lg-min) {
          height: 50px;
          width: 50px;
        }

        &::before {
            content: "";
            display: block;
            background: white;
            padding-bottom: 100%;
        }

        &.icon-facebook {
            mask-image: url("../images/icons/facebook.svg");
        }
        &.icon-instagram {
            mask-image: url("../images/icons/instagram.svg");
        }
        &.icon-youtube {
            mask-image: url("../images/icons/youtube.svg");
        }
    }
}