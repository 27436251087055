.news-list-page {
  .pimcore_area_ZentisText {
    max-width: 616px;
    margin: 0 auto;
  }

  .news-list {
    .news-items {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0 -12px;
      padding: 0;
    }

    .news-item {
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 24px rgb(0 0 0 / 8%);
      border-radius: 12px;
      overflow: hidden;
      margin: 12px;
    }

    .news-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 16px;
    }

    .news-link-wrap {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;
    }

    .news-link {
      margin-top: 24px;
      &:not(.btn){
        display: flex;
        align-items: center;
        color: $zentis-red;
        text-decoration: none;
        margin-left: auto;
      }


      &.btn {
        @include button-color($zentis-red, '#FFF');
        margin: 0;
        margin-top: 40px;
        border-radius: 8px;
      }

      .icon {
        background-color: currentColor;
        margin-left: 8px;
        width: 16px;
      }
    }

    .news-dateloc {
      margin-bottom: 8px;
      font-weight: 300;
      font-size: 14px;
      color: $zentis-gray-900;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;

      +.news-link-wrap {
        .news-link {
          margin-top: 8px;
        }
      }
    }
  }

  .news-item:first-child {
    h3 {
      font-size: 32px;
      line-height: 48px;
    }

    .news-body {
      padding: 24px;
    }

    .news-link-wrap {
      justify-content: flex-start;
    }
  }

}

@media (min-width: $screen-md-min) {
  .news-list-page {
    .news-item {
      width: calc(33.3% - 24px);

      &:first-child{
        width: 100%;
      }
      &:nth-child(2), &:nth-child(3){
        width: calc(50% - 24px);
      }
    }
  }
}

/*


@media (min-width: $screen-md-min) {
  .news-list {
    .news-item {
      display: flex;
      margin: 0 -8px;

      > div {
        flex: 0 0 50%;
        margin: 0 8px;
      }
    }

    .news-teaser {
      margin-bottom: 32px;
    }
  }
}*/
