.cc-window {
  background: #fff;
  border-top: 1px #a7a7a8 solid;
}

.cc-revoke {
  @include zentis-theme() {
    background-color: theme-value('buttonColor');
    color: theme-value('buttonTextColor');
  }
}

.cc-window.cc-floating {
  max-width: 100% !important;
}

@media screen and (min-width: $screen-md-min) {
  .cc-window.cc-floating {
    flex-direction: row !important;
  }
}

@media screen and (max-width: $screen-lg-min) {
  .cc-animate {
   transform: none!important;
  }
}
