$container-max-widths: (
    xl: 1000px
) !default;

$grid-gutter-widths: (
    xs: 32px,
    md: 48px,
);

$grid-gutter-width: map-get($grid-gutter-widths, xs);


@import '~bootstrap/scss/bootstrap-grid';

@include media-breakpoint-up($screen-lg-min) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1000px;
  }
}

.container {
  .container {
    padding: 0;
  }
}

.container {
    @each $breakpoint, $gutter in $grid-gutter-widths {
        @include media-breakpoint-up($breakpoint) {
            @include make-container($gutter);

            .container-fluid{
              padding-left: calc($gutter / 2);
            }
        }
    }

    @include media-breakpoint-up($screen-lg-min) {
      .container-fluid{
        width: 100%;
        margin: 0 auto;
      }
    }
}

.container-fluid {
  @each $breakpoint, $gutter in $grid-gutter-widths {
    @include media-breakpoint-up($breakpoint) {
      @include make-container($gutter);
    }
  }

  @include breakpoint-desktop-navi() {
    width: 100vw;
    margin-left: -80px;
    padding-left: calc(80px + (#{map-get($grid-gutter-widths, "md")} / 2));
  }
}

.row {
    @each $breakpoint, $gutter in $grid-gutter-widths {
        @include media-breakpoint-up($breakpoint) {
            @include make-row($gutter);

            &.no-gutters {
              margin-right: 0;
              margin-left: 0;

              > .col,
              > [class*="col-"] {
                padding-right: 0;
                padding-left: 0;
              }
            }
        }
    }
}

.row > .col,
.row > [class*='col-'] {
    @each $breakpoint, $gutter in $grid-gutter-widths {
        @include media-breakpoint-up($breakpoint) {
            @include make-col-ready($gutter);
        }
    }
}