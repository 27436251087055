.bg-container {
  @include breakpoint-desktop-navi() {
    margin-left: -80px;
    width: 100vw;
    padding-left: 80px;
  }
}

.bg-paper {
  background-image: url("../images/backgrounds/paper.jpg");
}

.bg-paper-dark {
  background-image: url("../images/backgrounds/paper-dark.jpg");
}