$btn-disabled: $zentis-gray-light;

@keyframes btn-pressed {
  0% {
    background-color: var(--bgColor);
    background-image: radial-gradient(closest-side, var(--bgAnimationColor) 0%, var(--bgAnimationColor) 90%, var(--bgColor));
    background-size: 40px 150%;
    background-repeat: no-repeat;
    background-position: 30% center;
  }
  90% {
    background-size: 150% 150%;
  }
  90% {
    background-color: var(--bgAnimationColor);
  }
}

.btn {

  @include zentis-theme() {
    --bgColor: #{theme-value('buttonColor')};
    --textColor: #{theme-value('buttonTextColor')};
    --bgAnimationColor: #{lighten(theme-value('buttonColor'), 10)};
    --bgFocusColor: #{lighten(theme-value('buttonColor'), 10)};
  }

  display: inline-flex;
  align-items: center;
  padding: 16px;
  margin: 16px 8px 8px 8px;
  border-radius: 3px;
  text-decoration: none;
  color: var(--textColor);
  font-size: 16px;
  line-height: 1;
  font-family: $font-fakt;
  border: 1px solid var(--bgColor);
  background: var(--bgColor);
  cursor: pointer;

    &.download{
        position: relative;
        padding-right: 32px;
        &:after{
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            display: block;
            height: 16px;
            width: 16px;
            mask-image: url("../images/icons/download.svg");
            mask-size: 16px;
            background: var(--textColor);
        }
    }

  &:focus{
    outline: none;
    background: var(--bgFocusColor);
    border-color: var(--bgFocusColor);
    //animation: btn-pressed 1400ms ease;
  }

  &:hover {
    box-shadow: 0 0 6px 1px rgba(0,0,0,0.4);
    color: var(--textColor);
  }

  &:active {
    animation: btn-pressed 250ms ease;
  }


  &.btn-disabled, &[disabled] {
    background: $btn-disabled;
    border-color: $btn-disabled;
    cursor: default;
    &:hover {
      box-shadow: none;
    }
    &:active {
      animation: none;
    }
  }

  .icon {
    width: 16px;
    background-color: var(--textColor);
    margin-right: 8px;
    &.btn-icon-right-side{
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &.btn-rounded-corners {
    border-radius: 8px;
  }
}

.btn-secondary {
  @include zentis-theme() {
    --bgColor: #FFFFFF;
    --bgFocusColor: #FFFFFF;
    color: theme-value('buttonColor');
    border-color: theme-value('buttonColor');
    --bgAnimationColor: #{lighten(theme-value('buttonColor'), 30)};

    &:focus {
      border-color: theme-value('buttonColor');
    }

    &.download:after{
        background: theme-value('buttonColor');
    }

    &.btn-disabled, &[disabled] {
      background: #FFF;
      color: $btn-disabled;
      border-color: $btn-disabled;
    }
  }

  .icon {
    background-color: currentColor;
  }
}

.btn-red-dark {
  @include zentis-theme() {
    --bgColor: #{$zentis-red-dark};
    --bgAnimationColor: #{lighten($zentis-red-dark, 10)};
    --bgFocusColor: #{lighten($zentis-red-dark, 10)};
  }
}

.btn-row{
  margin-left: -8px;
  margin-right: -8px;

  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}

.btn-recipe{
  @include zentis-theme() {
    --textColor: #{$zentis-green};
  }
  display: inline-flex;
  align-items: center;
  padding: 16px;
  margin: 16px 8px 8px 8px;
  border-radius: 3px;
  text-decoration: none;
  color: var(--textColor);
  font-size: 14px;
  line-height: 1;
  font-family: $font-fakt;
  cursor: pointer;

  &.download,
  &.print,
  &.mail{
    position: relative;
    padding-top: 64px;
    &:before{
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      display: block;
      height: 48px;
      width: 48px;
      mask-size: 48px;
      background: var(--textColor);
    }
  }
  &.download{
    &:before{
      mask-image: url("../images/icons/download-nr2.svg");
      //transform: translateX(35%);
    }
  }
  &.print{
    &:before{
      mask-image: url("../images/icons/print.svg");
      //transform: translateX(5%);
    }
  }
  &.mail{
    &:before{
      mask-image: url("../images/icons/mail.svg");
      //transform: translateX(50%);
    }
  }
  &:focus{
    outline: none;
    background: var(--bgFocusColor);
    border-color: var(--bgFocusColor);
    //animation: btn-pressed 1400ms ease;
  }
  &:hover {
    //box-shadow: 0 0 6px 1px rgba(0,0,0,0.4);
    color: $zentis-gray-900;
    --textColor: #282828;
  }
  &:active {
    animation: btn-pressed 250ms ease;
  }

  &.btn-disabled, &[disabled] {
    background: $btn-disabled;
    border-color: $btn-disabled;
    cursor: default;
    &:hover {
      box-shadow: none;
    }
    &:active {
      animation: none;
    }
  }
}