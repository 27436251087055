.bg-fruits-layout {
  position: relative;

  .fruits-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg-fruits-image{
    position: absolute;
    z-index: 0;
    width: 240px;
    height: 240px;
    background: transparent no-repeat center;
    background-size: contain;
  }

  .bg-fruits-image-strawberry{
    background-image: url("../images/fruits/strawberry.png");
  }

  .bg-fruits-image-strawberry2{
    background-image: url("../images/fruits/strawberry2.png");
  }

  .bg-fruits-image-apricot{
    background-image: url("../images/fruits/apricot.png");
  }

  .bg-fruits-image-blueberry{
    background-image: url("../images/fruits/blueberry.png");
  }

  .bg-fruits-image-cherry{
    background-image: url("../images/fruits/cherry.png");
  }

  .bg-fruits-image-blackcherry{
    background-image: url("../images/fruits/blackcherry.png");
  }

  .bg-fruits-image-raspberry{
    background-image: url("../images/fruits/raspberry.png");
  }

  .bg-fruits-image-plum{
    background-image: url("../images/fruits/plum.png");
  }

  .bg-fruits-image-wildberry{
    background-image: url("../images/fruits/wildberry.png");
  }

  .bg-fruits-image-almond{
    background-image: url("../images/fruits/almond.png");
  }

  .bg-fruits-image-hazelnut{
    background-image: url("../images/fruits/hazelnut.png");
  }

  .bg-fruits-image-1 {
    width: 160px;
    height: 160px;
    left: -80px;
    transform: translate(-100%, -50%);
  }

  .bg-fruits-image-2 {
    width: 120px;
    height: 120px;
    left: 0;
    bottom: 0;
    transform: translate(-80%, 20%);
  }

  .bg-fruits-image-3 {
    width: 120px;
    height: 120px;
    right: 0;
    top: 62px;
    transform: translate(100%, 0);
  }

  .bg-fruits-image-4 {
    width: 120px;
    height: 120px;
    right: 0;
    bottom: 0;
    transform: translate(70%, 50%);
  }
}