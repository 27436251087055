.icon-links {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  .icon-col {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 0;
    min-width: 120px;
    margin-bottom: 24px;
    justify-content: center;
    text-align: center;
    align-content: flex-start;
  }

  .icon-link {
    text-decoration: none;
    @include zentis-theme() {
      color: theme-value('textColor');
    }

    &:hover{
      color: $zentis-gray-900;
    }

  }

  .icon-image {
    width: 48px;
    height: auto;
  }

  .icon-svg {
    display: inline-block;
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-image: var(--bg-svg-img);
    background-color: currentColor;
    margin-bottom: 16px;

    .icon-image{
      visibility: hidden;
      margin-bottom: 0;
      display: block;
    }
  }

  .icon-title {
    display: block;
  }
}
