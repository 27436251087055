footer {
  padding: 96px 0;
  margin-bottom: 0 !important;
  @include zentis-theme() {
    background-color: theme-value('footer-backgroundColor');
  }

  div > [class*='col-'] {
    color: white;
    margin-bottom: 30px;

    p {
      font-size: 1.5rem;
      font-family: $font-fakt;
      font-weight: lighter;
      margin: 0;
      color: white;
    }

    ul {
      list-style-type: none;
      color: white;
      padding: 0;
      margin: 0;

      li {
        margin: 5px 0;

        a {
          font-weight: lighter;
          color: white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .footer-copyright {
          font-size: 1.0rem;
          font-weight: lighter;
          white-space: nowrap;
        }
      }
    }

    .social-networks {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
}