#recipe-detail{
  .tags{
    &.header-tags{
      margin-bottom: 32px;
    }
    .tag{
      @include zentis-theme() {
        background-color: lighten(theme-value('footer-backgroundColor'),25%);
      }
      display: inline-block;
      padding: 1px 9px;
      border-radius: 4px;
      margin-top: 20px;
      margin-right:20px;
      p{
        font-size: 14px;
      }
    }
  }
  .header-container{
    margin-top: 34px;
    margin-bottom: 40px;
    .author-container{
      font-size: 14px;
      padding-top: 16px;
      .author-image-container{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width:64px;
          height:64px;
          border-radius: 50%;
        }
      }
    }
    .header-image{
      margin-top: 24px;
    }
    .btn-row{
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
  .row-entry{
    td{
      &:first-child{
        white-space: nowrap;
        width: 90px;
        vertical-align: top;
      }
    }
  }
  .loop-index{
    margin-left: 24px;
    font-size: 28px;
  }
  .col-10{
    margin-left: -24px;
  }
}
.recipe-instruction{
  @media (min-width: 768px) {
    #preparation {
      order: 1;
    }
    #ingredients {
      padding-left: 80px;
      order: 2;
    }
  }
  #preparation{
    .index-container{
      display: flex;
      justify-content: center;
      @media (min-width: 768px) {
        justify-content: revert;
      }
    }
  }
  .used-products{
    &.desktop{
     display: none;
    }
    @media(min-width: 768px){
      &.desktop{
        display: inherit;
      }
      &.mobile{
        display: none;
      }
    }
  }
}
.extra-title{
  margin-bottom: 40px;
  padding: 0 12px 21px 12px;
  @include zentis-theme() {
    border: 1px solid theme-value('buttonColor');
  }
  .icon-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    .icon{
      height: 2rem;
      width: 2rem;
      @media (min-width: 768px) {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
  > div{
    padding: 21px 0;
  }
}
.related-recipes-container{
  .show-more-wrapper {
    .show-more-link {
      color: $zentis-green !important;
      .icon {
        background-color: $zentis-green !important;
      }
    }
  }
}
.slider-container-desktop, .slider-container-mobile{
  .slider-container-arrow-next, .slider-container-arrow-prev{
    position: absolute;
    top:50%;
    z-index: 1000;
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    background: transparent url("../images/icons/arrow.svg") no-repeat 50%;
    background-size: 16px;
    &:hover{
      cursor: pointer;
    }
  }
  .slider-container-arrow-next{
    right: 16px;
    transform: rotate(180deg);
    @media(min-width: 768px){
      right: 0;
    }
  }
  .slider-container-arrow-prev{
    left: 16px;
    @media(min-width: 768px){
      left: 0;
    }
  }
  margin-top: 40px;
}
.m-xxl-b{
  margin-top: 40px;
}
.recipe-item-content{
  border-radius: 0px 0px 24px 34px;
}
.slick-dots li button::before{
  font-size: 8px;
}
