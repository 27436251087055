.recipe-listing-wrapper {
  .h2 {
    margin-bottom: 24px;
  }

  form[name="recipe"] {
    position: sticky;
    top: 64px;
    background-color: #FFF;
    z-index: 1;
    @include media-breakpoint-up(md){
      top: 0;
    }
  }
}

.recipe-search-form-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .recipe-search-input-wrapper,
  .recipe-search-categories-wrapper {
    flex-basis: 100%;
    .form-inline{
      width: 100% !important;
      flex-wrap: wrap !important;
    }
  }

  .recipe-search-input-wrapper {
    @include media-breakpoint-up(md){
      flex-basis: auto;
      flex-grow: 1;
      margin-right: 16px;
    }
  }
  .recipe-search-submit-wrapper {
    order: 3;
  }

  .recipe-search-categories-wrapper {
    order: 2;
    width: 100%;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md){
      order: 4;
    }

    .is-hidden {
      display: none;
    }

    .form-check-label {
      white-space: nowrap;
      border: 1px solid $zentis-green;
      border-radius: 4px;
      font-weight: 300;
      padding: 5px 16px;
      font-size: 14px;
      color: $zentis-gray-900;
      cursor: pointer;

      @include zentis-theme() {
        border-color: theme-value('buttonColor');
      }

      &:before {
        display: none !important;
      }
    }
    .form-check-label:hover{
      box-shadow: 0 0 6px 1px rgba(0,0,0,.4);
    }

    .form-row {
      width: 100%;
      overflow: auto;

      .form-inline {
        width: max-content;
      }
    }

    .recipe-categories-prev, .recipe-categories-next {
      width: 55px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      display: flex;
      align-items: center;

      .icon {
        width: 8px;
      }
    }

    .recipe-categories-next {
      left: auto;
      right: 0;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      justify-content: flex-end;
    }
  }

  .recipe-search-input-container {
    display: flex;
    align-items: center;
    border: 1px solid $zentis-green;
    border-radius: 4px;
    padding: 8px 16px;
    @include zentis-theme() {
      border-color: theme-value('buttonColor');
    }

    .form-control:valid{
      box-shadow: none !important;
    }
    .recipe-search-input {
      height: 27px;
      &:focus{
        box-shadow: none;
      }
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
    .icon-search {
      flex-shrink: 0;
      width: 16px;
    }

    .recipe-selected-categories-wrapper {

    }

    .recipe-category-btn {
      margin: 0 4px;
      padding: 4px 8px;
      border-radius: 4px;
      --bgColor: #{$zentis-gray-400};
      --bgAnimationColor: #{lighten($zentis-gray-400, 5)};

      &:first-child {
        margin-left: 8px;
      }
      .icon-close {
        width: 8px;
      }
    }

    .recipe-search-outer-input {
      flex-grow: 1;
      overflow: hidden;
      position: relative;

      .form-row {
        margin: 0;
      }

      .recipe-search-clear-btn {
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background: $zentis-gray-400;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        --bgColor: #{$zentis-gray-400};
        --bgAnimationColor: #{lighten($zentis-gray-400, 5)};

        padding: 0;
        margin: 0;

        .icon-close {
          width: 8px;
          background-color: #FFF;
          margin: 0;
        }
      }
    }

    .form-input {
      padding: 0;
      margin-left: 16px;

      .form-control {
        border: none;
        padding: 0;
        min-width: auto;
      }
    }
  }
}

.recipe-container, .related-recipes-container {

  .recipe-items-container {
    display: flex;
    flex-wrap: wrap;
    margin: 32px -12px 0;
    justify-content: space-between;
  }

  .recipe-item {
    transform: scale(1);
    opacity: 1;
    transition: transform 0.2s linear, opacity 0.2s linear;
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    padding: 0 12px;
    width: 100%;

    @include media-breakpoint-up(sm){
      width: 50%;
    }

    @include media-breakpoint-up(md){
      width: 33%;
    }


    .recipe-item-headline {
      font-family: $font-calluna;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
    }

    .recipe-item-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 16px;
      background-color: #FBFBFC;

      .recipe-item-description {
        line-height: 1.571;
        font-size: 14px;
        margin: 16px 0;
      }
    }

    .show-more-wrapper {
      margin-top: auto;

      .show-more-link {
        color: $zentis-gray-900;

        .icon {
          background-color: $zentis-gray-400;
        }
      }
    }

    &.hide-recipe-item {
      transform: scale(0.6);
      opacity: 0;
    }

    .recipe-item-image {
      border-radius: 24px 34px 0 0;
      display: block;
    }

    .show-more-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  &.hide-recipe-items-container {
    .recipe-item {
      transform: scale(0.6);
      opacity: 0;
    }
  }

  .recipe-pagination {
    display: flex;
    justify-content: center;

    .recipe-show-more-btn {
      color: $zentis-gray-900;
      .icon {
        @include zentis-theme() {
          background-color: theme-value('buttonColor');
        }
      }
    }
  }

  .recipe-list-empty-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12px;
    margin: 32px auto 32px;

    .recipe-list-empty-icon {
      background-color: #c4121f;
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 24px;
      .icon {
        display: block;
        background-color: #FFF;
      }
    }

    .recipe-list-empty-text {
      max-width: 456px;
      font-size: 24px;
      line-height: 1.333;
      text-align: center;
      margin-bottom: 24px;
    }

    .recipe-list-empty-try-again {
      color: #c4121f;
      text-decoration: underline;
    }
  }
}