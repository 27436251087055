.dvinci-job-widget{
  padding: 28px;
  max-width: 1024px;
  margin: 0 auto;
}

.dvinci-job-widget {
  div[role="left-column"] {
    max-width: none;
    flex-basis: 100%;

    .dvinci-job-filters {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px 20px;

      .dvinci-job-filter {
        padding: 0 10px 0;
        margin-bottom: 10px;
        flex: 1 1 auto;
        width: 100%;
        @media (min-width: 516px) {
          max-width: 220px;
        }
      }
    }
  }

  div[role="right-column"] {
    max-width: none;
    flex-basis: 100%;
  }
}

.dvinci-job-filter[dvinci-job-filter-categories] {
  display: none;
}

.dvinci-job-filter[dvinci-job-filter-target-groups] {
  .ng-scope{
    visibility: hidden;
    position: relative;
    &:after{
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      content: "Karrierelevel";
    }
  }
}

.dvinci-job-widget .well-sm{
  border-radius: 4px;
  border: solid 2px $zentis-green;
}

.dvinci-job-widget
.ui-select-multiple.ui-select-bootstrap{
  padding: 8px;
  border-radius: 4px;
  border: solid 2px $zentis-green;
  background-color: #fff;
}

.dvinci-job-widget
.grid[min-width~="800px"] .col-md-4{
  margin-top: -36px;
}

.dvinci-job-widget
.dvinci-job-filters
.dvinci-job-filter
.dvinci-filter-heading{
  padding-left: 8px;
  color: $zentis-gray-900;
}

.dvinci-job-widget
.ui-select-multiple.ui-select-bootstrap input.ui-select-search{
  color: $zentis-gray-700;
}

.dvinci-job-widget
.dvinci-job-filters
.dvinci-job-filter
.ui-select-match-item{
  color: white;
  font-weight: 500;
  font-size: 16px;
  border: solid 2px $zentis-green;
  border-radius: 4px;
  background-color: $zentis-green;
}
.dvinci-job-widget:hover
.dvinci-job-filters:hover
.dvinci-job-filter:hover
.ui-select-match-item:hover{
  background-color: $zentis-green-700;
  border: solid 2px $zentis-green-700;
}

.dvinci-job-widget
.dvinci-job-filters
.dvinci-job-filter
.ui-select-match-item
.ui-select-match-close{
  @extend .icon;
  @extend .icon-close;
  position: absolute;
  right: 4px;
  top: 10px;
  color: transparent;
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
}



.dvinci-job-widget
.ui-select-bootstrap > .ui-select-choices{
  margin-left: -2px;
  width: 102%;
  border: 2px solid #74b94d;
  border-radius: 4px;
}

.dvinci-job-widget
.ui-select-bootstrap
.ui-select-choices-row.active > span{
  background-color: #fff;
  color: $zentis-gray-900;
}

.dvinci-job-widget:hover
.ui-select-bootstrap:hover
.ui-select-choices-row:hover > span:hover{
  color: $zentis-gray-900;
  background-color: $zentis-green-200;
  .dvinci-filter-option-match-count{
    background-color: #fff;
  }
}

.dvinci-job-widget
.ui-select-bootstrap
.ui-select-choices-row > span:focus,
.dvinci-job-widget
.ui-select-bootstrap
.ui-select-choices-row > span:hover{
  color: $zentis-gray-900;
  background-color: $zentis-green-200;
}

.dvinci-job-widget
.dvinci-job-filters
.dvinci-job-filter
.ui-select-choices-row
.dvinci-filter-option-match-count{
  color: $zentis-gray-900;
  background-color: $zentis-green-200;
  border-radius: 10px;
}

.dvinci-job-widget:hover
.dvinci-job-filters:hover
.dvinci-job-filter:hover
.ui-select-choices-row:hover
.dvinci-filter-option-match-count:hover{
  color: $zentis-gray-900;
  background-color: #fff;
}

.dvinci-job-widget
.dvinci-job-list .well{
  padding: 16px;
  background-color: #fff !important;
  margin-bottom: 16px;
}

.dvinci-job-widget .badge, .dvinci-job-widget .label{
  position: absolute;
  top: 40%;
  right: 4%;
}
