ul.pagination {
  list-style-type: none;
  margin: 16px -4px;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  li {
    display: inline-block;
    background-color: $zentis-pagination-bg;
    margin: 8px;

    &.page-dots {
      background-color: transparent;
    }

    &.page-item {
      background: none;
      .btn {
        margin: 0;
      }

      &.active {
        background: none;
        .btn {
          --bgColor: #{$zentis-pagination-cur};
        }
      }
    }

    &.active {
      background-color: $zentis-pagination-cur;

      .page-link {
        cursor: default;
        color: $zentis-red;
        border-color: $zentis-red;
      }
    }

  }

  .page-link {
    display: inline-flex;
    min-width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $zentis-pagination-text;
    border: 1px solid $zentis-pagination-text;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 300;

    .icon {
      background-color: $zentis-pagination-text;
      width: 18px;
    }

  }

  .page-result {
    color: $zentis-pagination-text;
    margin-left: 24px;
  }

}
