@mixin scrollbar() {
  overflow-y: auto;
  //noinspection CssInvalidPropertyValue
  overflow-y: overlay;
  scrollbar-width: thin;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.35);;
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 15px;
    position: relative;
  }
}