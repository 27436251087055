@import "../functions/spacing-system";
@import "breakpoints";

$margin-xxs: spacing-system(1);
$margin-xs: spacing-system(2);
$margin-s: spacing-system(3);
$margin-m: spacing-system(5);
$margin-l: spacing-system(7);
$margin-xl: spacing-system(10);
$margin-xxl: spacing-system(10);
$margin-xxxl: spacing-system(10);


$padding-xxs: spacing-system(1);
$padding-xs: spacing-system(2);
$padding-s: spacing-system(3);
$padding-m: spacing-system(5);
$padding-l: spacing-system(7);
$padding-xl: spacing-system(10);
$padding-xxl: spacing-system(10);
$padding-xxxl: spacing-system(10);


@media screen and (min-device-width: $screen-md-min){
  $margin-xxl: spacing-system(13);
  $margin-xxxl: spacing-system(15);
  $padding-xxl: spacing-system(13);
  $padding-xxxl: spacing-system(15);
}