.news-detail-page {

  .news-img {
    display: block;

    @media (min-width: $desktop-navi-min) {
      margin-left: -80px;
    }
  }

  .news-detail {
    .news-title {
      margin-bottom: 40px;
      text-align: center;
    }

    .news-info {
      margin-bottom: 80px;
      text-align: center;
    }

    .news-links {
      display: flex;
      justify-content: center;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        text-decoration: none;
        margin: 0 24px;
        color: var(--textColor);
      }

      svg {
        width: 48px;
        height: auto;
        margin-bottom: 8px;
      }

      &.news-links-bottom {
        margin-top: 64px;
        margin-bottom: 64px;
      }
    }

    .news-teaser {
      max-width: 616px;
      margin: 0 auto 16px;
    }

    .news-body {

    }

    .news-text {
      max-width: 616px;
      margin: 0 auto;

      p {
        margin-bottom: 16px;
      }
    }

    .news-headline {
      margin: 64px 0;
      text-align: center;
    }

    .news-image {
      width: 100vw;
      margin: 80px 0;

      @media screen and (min-width: $desktop-navi-min) {
        margin-left: -80px;
      }
      img {
        display: block;
      }
    }
  }
}