.video-layer-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFF;
  z-index: 100;

  &.open {
    display: block;
  }

  .video-layer-close{
    position: absolute;
    top: 25px;
    right: 25px;
    background: #FFF;
    z-index: 10;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;

    .icon {
      width: 16px;
      display: block;
    }

    @media (orientation: landscape) {
      display: none;
    }

    @media screen and (min-width: $screen-md-min) and (min-height: 480px){
      display: block;
    }
  }

  &.zentis-video-type-youtube {
    .video-layer-close{
      right: 20px;
      top: 20px;
    }
    .zentis-video-accepted + .video-layer-close {
      top: 60px;
    }
  }

  .zentis-video-wrap {
    padding: 0;
    width: 100%;
    height: 100% !important;
    max-width: 100%;
    max-height: 100%;
  }

  .zentis-internal-video {
    background: #000;
  }
}