header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include zentis-theme() {
        background-color: theme-value('header-backgroundColor');
    }

    .header-logo{
        img{
            max-width:250px;
            height:200px;
        }
    }
}
