@import "../../themes/themes";

@mixin zentis-theme($onSelf: false) {

  @each $theme, $map in $themes {
    $classSeparator: if($onSelf, '&.theme-#{$theme}', '.theme-#{$theme} &');
    #{$classSeparator} {
      $current-theme: map-get($themes, $theme) !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $current-theme: map-merge($current-theme, ($key: $value)) !global;
      }

      @content;
      $current-theme: null !global;
    }
  }

  @each $theme, $map in $themes {
    $classSeparatorOverride: if($onSelf, '&.theme-override-#{$theme}', '.theme-override-#{$theme} &');
    #{$classSeparatorOverride} {
      $current-theme: map-get($themes, $theme) !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $current-theme: map-merge($current-theme, ($key: $value)) !global;
      }

      @content;
      $current-theme: null !global;
    }
  }
}