.dropdown-menu {
  position: relative;
  display: inline-block;

  .dropdown-title{
    padding-right: 32px;
    display: inline-block;
    min-width: 24px;
    &:after{
      content: "";
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      right: 0;
      top: 0;
      background: transparent url("../images/icons/arrow-thin.svg") no-repeat center center;
      background-size: 16px;
      transform: rotate(-90deg);
    }
  }

  .dropdown-container {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    display: none;
    box-sizing: border-box;
    padding: 8px;
    margin: 0 -8px;
    background: $zentis-gray-light;
    min-width: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &.opened {
    .dropdown-container {
      display: block;
    }
  }
}