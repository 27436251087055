$zentis-red: #C31924;
$zentis-red-dark: #590500;
$zentis-green: #74B94D;
$zentis-green-200: #C1E5AB;
$zentis-green-700: #61A543;
$zentis-green-900: #2D6E28;
$zentis-gray-400: #C5C5C6;
$zentis-gray-700: #69696A;
$zentis-gray-900: #282828;
$zentis-gray-middle: #A7A7A8;
$zentis-gray-light: #E3E3E3;

$zentis-naturrein: #848f4e;
$zentis-aprikose: #F9B300;
$zentis-pfluemli: #103262;
$zentis-erdnuss: #0081A5;
$zentis-belmandel: #C18B01;
$zentis-quinoa: #185D7B;
$zentis-em-premium: #302920;
$zentis-belcoco: #88CEE7;
$zentis-em-perlen: #88353F;

$zentis-famos-red-1: #DC7575;
$zentis-famos-red-2: #FFA2AD;
$zentis-famos-green-1:#A4DE6E;
$zentis-famos-green-2: #C8EFA4;

$zentis-pagination-bg: #f5f5f5;
$zentis-pagination-cur: #008eff;
$zentis-pagination-text: #707172;
