.slider {
    width: 90%;
    margin: auto;
    .slick-arrow::before{
        display: none;
    }
}

@media screen and (min-width: $screen-md-min) {
    .slider {
        width: 50%;
        height: auto;
        margin: auto;
        .slider_image{
            width: auto;
        }
        .slick-arrow::before{
            color: black;
            display: block;
        }
    }
}

.slick-dotted.slick-slider {
  margin-bottom: 0px !important;
}

.slider-stage {
  visibility: hidden;
  opacity: 0;
  margin: 0;
  max-height: 100vh;
  line-height: 0;

  &.slick-initialized { 
    visibility: visible;
    transition: opacity 800ms;  
    opacity: 1; 
  }

  .zentis-video-wrap {
    width: 100%;
    max-height: 100vh;
  }

  @media not all and (min-width: $screen-lg-min) {
    margin-top: 64px;
  }
  
  .slick-dots {
    bottom: 32px;
    li {
      button:before {
        font-size: 16px;
        color: var(--dots-color);
      }
    }
  }
}