.product-teaser{
  .teaser-link-wrapper{
    all: unset;
    &[href]{
      cursor: pointer;
    }
  }
  a{
    padding: 16px;
  }

  &.align-left{
    text-align: left;
    a{
      margin-left: 0;
    }
  }

  &.align-right{
    text-align: right;
    a{
      margin-right: 0;
    }
  }

  &.align-center{
    text-align: center;
  }
  &.large, &.medium, &.small{
     p {
      font-family: $font-fakt
    };
  }
  &.large{
     p {
      font-size: 24px;
      line-height: 36px;
    }
  }
  &.medium{
     p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &.small{
     p {
      font-size: 14px;
      line-height: 24px;
    }
  }
  &.normal{
     p {
      font-weight: normal;
    }
  }
  &.light{
     p {
      font-weight: lighter;
    }
  }

  img.no-scale {
    width: auto;
    max-width: 100%;
  }

  p.teaser-subheader {
    font-size: 0.875rem;
    line-height: 14px;
    margin-top: 16px;
    font-weight: 300;
  }

}