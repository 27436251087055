.autoComplete_highlighted {
  color: $zentis-green;
}

.autoComplete_result:hover {
  background-color: transparentize($zentis-green, 0.8);
}

#autoComplete_list {
  margin: 0 8px;
}

.search-content-container {
  margin-top: 32px;
}

.search-form-controls {
  > .form-row {
    flex-grow: 1;
  }
}

.search-result-item {
  margin-bottom: 24px;
}