$error-color: red;

/** Default input stylings */
.form-inline {
  display: flex;
  align-items: flex-start;
}

.form-row {
  margin: 0 -8px;
}

.form-info{
  font-size: 12px;
  padding-left: 8px;
  margin-top: -8px;
}

.form-subheader{
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 8px;
}

input.form-input-email {
  height: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none !important;
  box-shadow: none !important;
  display: block;
}

.form-group {

  padding: 8px;
  --form-basic-color: #000;

  &.form-disabled {
    --form-basic-color: #ccc;
  }

  .form-caption {
    padding-top: 8px;
    font-size: 12px;
    font-weight: 300;
    color: var(--form-basic-color);
  }

  &.form-error {
    --form-basic-color: #{$error-color} !important;

    .form-caption {
      color: var(--form-basic-color);
    }
  }

  &.form-input, &.form-select, &.form-textarea {
    position: relative;

    @include zentis-theme() {
      --form-basic-color: #{theme-value('buttonColor')};
    }

    &.form-disabled {
      --form-basic-color: #ccc;

      .form-control::placeholder {
        color: #ccc;
      }

      .form-caption {
        color: #ccc;
      }
    }

    .form-control {
      border: 1px solid var(--form-basic-color);
      box-shadow: 0 0 0 0 var(--form-basic-color);
      border-radius: 4px;
      padding: 16px 16px;
      font-size: 16px;
      transition: box-shadow 100ms ease;
      min-width: 230px;
      width: 100%;
      box-sizing: border-box;

      &:focus,
      &:valid{
        box-shadow: 0 0 0 1px var(--form-basic-color);
        outline: none;

        &::placeholder {

          visibility: hidden;
        }

        & + .form-label {
          visibility: visible;
          transform: scale(1) translateY(-50%);
        }

        & ~ .form-caption {
          color: var(--form-basic-color);
        }
      }
    }

    .form-label {
      position: absolute;
      top: 8px;
      left: 20px;
      transform: scale(0) translateY(-50%);
      background: #FFF;
      padding: 0 4px;
      font-size: 12px;
      color: var(--form-basic-color);
      visibility: hidden;
      transition: transform 200ms ease;
    }

    .form-caption {
      padding-left: 16px;
    }
  }

  /** Checkbox/Radio Stylings **/
  &.form-checkbox, &.form-radio {
    .form-control[type=checkbox], .form-control[type=radio] {
      opacity: 0;
      position: absolute;
      z-index: -1;

      + label {
        display: flex;

        &:before {
          content: "";
          display: block;
          margin-right: 16px;
          margin-top: 3px;
          width: 18px;
          height: 18px;
          box-sizing: border-box;
          background: #fff;
          border: 2px solid var(--form-basic-color);
          border-radius: 3px;
          flex-shrink: 0;
        }
      }

      &:checked + label:before {
        background: url("../images/icons/checked.svg") center;
      }

      &:disabled + label {
        --form-basic-color: #ccc;
      }
    }

    // specific radio styles
    .form-control[type=radio] {
      + label:before {
        border-radius: 50%;
      }

      &:checked + label:before {
        // TODO Background mit icon ersetzen
        background-color: #fff;
        background-image: radial-gradient(closest-side, #000 0%, #000 60%, #fff 60%, #fff);
        background-position: center;
        //background-size: 10px 10px;
        background-repeat: no-repeat;
      }
    }

    .form-caption {
      padding-left: 16px;
      padding-top: 0;
    }

    .form-group-option {
      padding-right: 16px;
    }
  }

  /** Selectbox **/
  &.form-select {

    .form-select-wrapper {
      position: relative;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        z-index: -2;
        position: absolute;
        background: #fff;
      }

      &:after {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: -1;
        mask-image: url("../images/icons/selectbox-down.svg");
        background: var(--form-basic-color);
      }
      .form-control{
        &:focus,
        &:valid {
          & + .form-label {
            visibility: visible;
            transform: scale(1) translateY(-100%);
          }
        }
      }
    }

    select.form-control {
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      //background: url("../images/icons/selectbox-down.svg") center right no-repeat;
      padding-right: 60px !important;
      background: transparent;
      &:focus {
        ~&:after{
          background: red;
        }
      }
    }
  }

  > button.btn {
    margin: 0;
  }
}


