
.scroll-top {
  transform: scale(0);
  display: flex;
  font-size: 0.75rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--textColor);
  cursor: pointer;
  position: fixed;
  transition: all 0.3s ease-in-out 0s;
  top: auto;
  bottom: 3rem;
  right: 3rem;
  left: auto;
  padding: 8px;
  width: 64px;
  height: 64px;
  border-radius: 50%;

  @include frostet-glass-bg;

  &:hover {
    box-shadow: 0 0 8px 4px rgb(0 0 0 / 16%)
  }

  svg {
    fill: currentColor;
    height: 20px;
    vertical-align: bottom;
    width: 20px;
    margin-top: -2px;
    flex-shrink: 0;
  }

  &.show {
    transform: scale(1);
  }
}